import React from 'react'
import { graphql } from "gatsby";
import Base from '../layouts/base';
import { GatsbyImage } from 'gatsby-plugin-image';
import Logo from '../components/logo/logo';
import { Helmet } from 'react-helmet';

function Links({data}) {

    const theLink = data.datoCmsLink


    return (
        <Base>
        <Helmet 
            title={theLink.name}
            description="Just another link curator."
      />
        <div className="base">
            <div className="holder">
                {theLink.featuredImage.gatsbyImageData && (
                    <GatsbyImage className="image" image={theLink.featuredImage.gatsbyImageData} alt={theLink.name}/>
                )}
                <div className="content">
                    <h1>{theLink.name}</h1>
                    <ul className="logo-list">
                        {theLink.spotify && (
                            <li><a href={theLink.spotify} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_spotify.svg" alt="Spotify"/>
                            </a></li>
                        )}

                        {theLink.appleMusic && (
                            <li><a href={theLink.appleMusic} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_applemusic.svg" alt="Apple Music"/>
                            </a></li>
                        )}

                        {theLink.youtube && (
                            <li><a href={theLink.youtube} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_youtube.svg" alt="YouTube"/>
                            </a></li>
                        )}
                        
                        {theLink.jiosaavn && (
                            <li><a href={theLink.jiosaavn} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_jiosaavn.svg" alt="JioSaavn"/>
                            </a></li>
                        )}

                        

                        {theLink.wynk && (
                            <li><a href={theLink.wynk} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_wynk.svg" alt="Wynk"/>
                            </a></li>
                        )}
                        
                        {theLink.hungama && (
                            <li><a href={theLink.hungama} rel='noreferrer' target='_blank'>
                                <img src="/logos/logo_hungama.svg" alt="Hungama"/>
                            </a></li>
                        )}
                        
                        {/* {theLink.amazon && (
                            <li><a href={theLink.amazon} rel='noreferrer' target='_blank'>Amazon Music</a></li>
                        )} */}
                        
                        {/* {theLink.gaana && (
                            <li><a href={theLink.gaana} rel='noreferrer' target='_blank'>Gaana</a></li>
                        )}
                        
                        {theLink.raaga && (
                            <li><a href={theLink.raaga} rel='noreferrer' target='_blank'>Raaga</a></li>
                        )}
                        
                        {theLink.tidal && (
                            <li><a href={theLink.tidal} rel='noreferrer' target='_blank'>Tidal</a></li>
                        )} */}
                    </ul>
                </div>

                {/* <div className="brand">
                    <Logo />
                </div> */}
            </div>
        </div>
        </Base>
    )
}

export default Links

export const linkQuery = graphql`
  query ($id: String) {
    datoCmsLink(id: { eq: $id }) {
    id
    name
    featuredImage {
            gatsbyImageData (width: 400, height: 400)
        }
    amazon
    appleMusic
    hungama
    gaana
    jiosaavn
    raaga
    slug
    spotify
    tidal
    wynk
    youtube
  }
  }
`;


